import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '@app/store/reducers/ui';
import { Select, Button, Input, Checkbox } from '@components';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { useHistory, useParams, Link } from 'react-router-dom';
import * as usersService from '../../services/users';
import * as Yup from 'yup';

const roles = [
    {
        id: '1',
        name: 'Admin'
    },
    {
        id: '2',
        name: 'SuperAdmin'
    }
]

const EditUsers = () => {
    const history = useHistory();
    const [users, setUsers] = useState(false);
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams()
    const [user, setUser] = useState();
    const [status, setStatus] = useState(1);

    const formik = useFormik({
        initialValues: {
            name: '',
            password: '',
            email: '',
            lastName: '',
            secondLastName: '',
            isSuperAdmin: false,
            status: false
        },
        validationSchema: Yup.object({
            userName: Yup.string()
                .min(5, t('validationSchema.min5Caracters'))
                .matches(/^(\S+$)/g, t('validationSchema.noBlankSpaces'))
                .required(t('validationSchema.userName')),
            password: Yup.string()
                .min(8, t('validationSchema.min8Caracters')),
            passwordConfirmation: Yup.string()
                .oneOf([Yup.ref('password'), null], t('validationSchema.passwordConfirm')),
            email: Yup.string()
                .email(t('validationSchema.emailValidate'))
                .required(t('validationSchema.requiredEmail')),
            name: Yup.string()
                .required(t('validationSchema.requiredUserName')),
            lastName: Yup.string()
               .required(t('validationSchema.requiredUserLastName')),
        secondLastName: Yup.string().required(t('validationSchema.requiredUserMotherLastName')),
            status: Yup.number()
        }),
        onSubmit: (values) => {
            usersData(values);
        }
    });



    useEffect(() => {
        dispatch(setLoading(true));
        usersService.getUserById(id)
            .then((response) => {
                formik.setValues({ ...response.data })
                setUser(response.data)
                dispatch(setLoading(false));
            }).catch(error => {
                console.log(error)
            })
    }, [])

    const usersData = async (data) => {
        data.status = status;
        console.log(data)
        try {
            const res = await usersService.updateUser(id, data);
            console.log(res)
            toast.success(t('admins.label.adminEdit'));
            history.push('/users');
        } catch (error) {
            setUsers(false);
            console.log(error.response.data.errors[0])
            const errorMessage = error.response.data.errors[0]
            toast.error(errorMessage);
        }

    }

    const handleDeleteUser = async () => {
        try {
            const respuesta = confirm(t('general.label.deleteRegister'))
            if (respuesta === true) {
                const data = await usersService.deleteUser(id)
                if (data.successful)
                    toast.success(t('admins.label.adminDeleted'));

                history.push('/users');
            } else {
                return false;
            }

        } catch (err) {
            console.log(err)
            toast.error('Hubo un problema al eliminar al Admin');
        }
    }
    const handleOnChange = () => {
        setSend(send);
    };

    const onChangeIsAdmin = (value) => {
        const newData = formik.values;
        newData.isSuperAdmin = value;
        formik.setValues({ ...newData }, true);
    }

    return (
        <div>
            {/*<ContentHeader title={t('users.label.edit')} buttons={headerButtons} />*/}
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header -p2">
                        <h3 className="text-danger font-weight-bold">{t('admins.label.editAdmin')}</h3>
                        <div class="mr-1 card-tools">
                            <Button
                                isLoading={users}
                                type="submit" block
                                onClick={handleDeleteUser}
                                className="btn btn-danger"><i className="icon-button fa fa-trash"></i></Button>
                        </div>
                    </div>
                    <div className="p-3">
                        {user ? <form className="form-horizontal" onSubmit={formik.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>{t('admins.label.userName')}</label>
                                    <Input
                                        value={user.userName}
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('userName')}
                                        type="text"
                                        placeholder={t('admins.label.userName')} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>{t('admins.label.password')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('password')}
                                        type="password"
                                        placeholder={t('admins.label.password')} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>{t('general.label.email')}</label>
                                    <Input
                                        value={user.email}
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('email')}
                                        type="email"
                                        disable
                                        placeholder={t('general.label.email')} />
                                </div>
                                <div className="form-group col-md-6">
                                    <label>{t('admins.label.confirmPassword')}</label>
                                    <Input
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('passwordConfirmation')}
                                        type="password"
                                        placeholder={t('admins.label.confirmPassword')} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label>{t('general.label.name')}</label>
                                    <Input
                                        value={user.name}
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('name')}
                                        type="text"
                                        placeholder={t('general.label.name')} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label>{t('admins.label.lastName')}</label>
                                    <Input
                                        value={user.lastName}
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('lastName')}
                                        type="text"
                                        placeholder={t('admins.label.lastName')} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label>{t('admins.label.motherLastName')}</label>
                                    <Input
                                        value={user.secondLastName}
                                        formik={formik}
                                        formikFieldProps={formik.getFieldProps('secondLastName')}
                                        type="text"
                                        placeholder={t('admins.label.motherLastName')} />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-sm-3">
                                    <label>{t('admins.label.rol')}</label>
                                    <Checkbox
                                        onChange={onChangeIsAdmin}
                                        checked={user.isSuperAdmin}
                                        label={t('admins.label.hasSuperAdmin')}
                                    />
                                </div>
                                {/*<div className="col-sm-3">*/}
                                {/*    <label*/}
                                {/*        htmlFor="inputName"*/}
                                {/*        className=" col-form-label"*/}
                                {/*    >*/}
                                {/*        ¿Desea suspender la cuenta del usuario?*/}
                                {/*    </label>*/}
                                {/*    {admin && admin.length > 0 ? (*/}

                                {/*            <Select*/}
                                {/*                items={admin}*/}
                                {/*                optionTitle="name"*/}
                                {/*            onChange={(item) => setStatus(item.value)}*/}
                                {/*            value={status}*/}
                                {/*                formik={formik}*/}
                                {/*                formikFieldProps={formik.getFieldProps('status')} />*/}

                                {/*    ) : null}*/}
                                {/*</div>*/}
                            </div>
                            <div className="form-row">


                            </div>
                            <div className="row p-2 pt-4">
                                <div className="col-2">
                                    <Link
                                        to={`/users/`}
                                        className="btn text-danger" > {t('general.buttons.cancel')} </Link>
                                </div>
                                <div className="col-sm-2">
                                    <Button
                                        isLoading={users}
                                        type="submit" block
                                        className="btn btn-danger" > {t('general.buttons.saveChanges')} </Button>
                                </div>
                            </div>
                        </form> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUsers;
